<template>
  <div
    style="
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 30px 0;
    "
  >
    <v-select
      v-model="selectedFirm"
      :items="firms"
      :menu-props="{ maxHeight: '400' }"
      style="width: 80%; margin: 0 0 30px 0"
      item-value="firmId"
      item-text="firmName"
      label="Firmalar"
      hint="Filtrelenecek Firma"
      outlined
      persistent-hint
      @change="sort"
    ></v-select>

    <div
      v-if="
        (firms.length === 0 || (selectedFirm && selected.length === 0)) &&
        !showTable
      "
    >
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <div v-else-if="!selectedFirm">
      <p style="font-style: italic">Lütfen Bir Firma Seçiniz</p>
    </div>
    <div v-else style="width: 90%">
      <v-data-table
        :headers="headers"
        :items="selected"
        :items-per-page="5"
        item-key="name"
        multi-sort
        class="elevation-2"
      >
        <template v-slot:[`item.candidates`]="{ item }">
          <v-icon @click="exportExcel(item)"> mdi-file-excel-box </v-icon>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import axios from '@axios';
import { json2excel } from 'js2excel';

export default {
  name: 'TestReportList',
  components: {},
  data: () => ({
    headers: [
      {
        text: 'Test',
        align: 'start',
        value: 'testName'
      },
      {
        text: 'Bitirilme Sayısı',
        align: 'center',
        value: 'count'
      },
      {
        text: 'Adaylar',
        align: 'end',
        value: 'candidates',
        sortable: false
      }
    ],
    showTable: false,
    firms: [],
    selected: [],
    selectedFirm: null
  }),
  mounted() {
    this.getFirms();
    this.showTable = false;
  },
  methods: {
    getFirms() {
      axios.get('/list-firm').then((response) => {
        response.data.result.forEach((e) => {
          this.firms.push({ firmName: e.name, firmId: e._id });
        });
      });
    },
    list() {
      axios
        .get('/firm-test-overview', {
          params: { id: this.selectedFirm }
        })
        .then((response) => {
          if (response.data.length !== 0) {
            response.data.forEach((i) => {
              this.selected.push({
                candidate: i.aday,
                testName: i.testName,
                count: i.finishedCount
              });
            });
          } else {
            this.showTable = true;
            this.$toast.warning('Test Bulunamadı');
          }
        });
    },
    sort() {
      this.selected = [];
      this.showTable = !this.showTable;
      this.list();
    },
    exportExcel(item) {
      try {
        json2excel({
          data: item.candidate,
          name: 'test-candidate-list',
          formateDate: 'yyyy/mm/dd'
        });
      } catch (e) {
        this.$toast.error(
          'Excel Aktarım Hatası.\nTablonun Dolu olduğuna emin olun'
        );
      }
    }
  }
};
</script>

<style lang="scss"></style>
